import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';

function Mission() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        paddingTop: '80px',
        backgroundColor: '#f8f9fa',
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#1a1a1a', marginTop: '50px' }}>
          Recharge Transport: Our Mission
        </Typography>
        <Grid container spacing={3} sx={{ marginBottom: '4rem', marginTop: '50px' }}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: '#fff' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Sustainability First
              </Typography>
              <Typography variant="body1" paragraph>
                Our mission is to drive the future of transportation towards sustainability. We are committed to reducing the environmental impact of transport through innovative solutions.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: '#fff' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Empowering Drivers
              </Typography>
              <Typography variant="body1" paragraph>
                We believe in empowering drivers with the tools and resources they need to embrace electric vehicles. Our platform makes it easy to recharge and manage your vehicle efficiently.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: '#fff' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Innovation in Transport
              </Typography>
              <Typography variant="body1" paragraph>
                We are at the forefront of transportation innovation, constantly seeking new ways to improve the recharging experience and make it accessible to everyone.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Mission;
