import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: '#1a1a1a',
        color: '#fff',
        padding: '2rem 0',
        boxShadow: '0px -4px 20px rgba(0, 0, 0, 0.2)',
      }}
    >
      <Container maxWidth="md">
        <Typography variant="body2" align="center" paragraph>
          © 2024 Recharge Road & Transport Authority. All rights reserved.
        </Typography>
        <Typography variant="body2" align="center" paragraph>
          894 Declan Parks Apt. 131
          +1 (220) 646-0632
        </Typography>
        <Box display="flex" justifyContent="center" gap="15px">
          <Link href="https://facebook.com" color="inherit" target="_blank">
            <FacebookIcon />
          </Link>
          <Link href="https://twitter.com" color="inherit" target="_blank">
            <TwitterIcon />
          </Link>
          <Link href="https://instagram.com" color="inherit" target="_blank">
            <InstagramIcon />
          </Link>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
