import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';

function RechargeGuide() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        paddingTop: '80px',
        backgroundColor: '#f8f9fa',
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#1a1a1a', marginTop: '50px' }}>
          How to Recharge Your Vehicle Online
        </Typography>
        <Grid container spacing={3} sx={{ marginBottom: '4rem', marginTop: '50px' }}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: '#fff' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Step 1: Create an Account
              </Typography>
              <Typography variant="body1" paragraph>
                To get started, register on our platform by creating an account. You will need to provide some basic information such as your name, email address, and vehicle details.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: '#fff' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Step 2: Add Your Vehicle
              </Typography>
              <Typography variant="body1" paragraph>
                After creating your account, add your vehicle to the system. This includes entering your vehicle's make, model, and license plate number to ensure accurate billing.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: '#fff' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Step 3: Recharge with Ease
              </Typography>
              <Typography variant="body1" paragraph>
                Finally, use our platform to recharge your vehicle. Simply select the recharge amount, confirm the transaction, and your vehicle will be ready to go in no time.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default RechargeGuide;
