import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';

function InfrastructureUpdates() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        paddingTop: '80px',
        backgroundColor: '#f8f9fa',
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#1a1a1a', marginTop: '50px' }}>
          Benefits of Online Vehicle Recharging
        </Typography>
        <Grid container spacing={3} sx={{ marginBottom: '4rem', marginTop: '50px' }}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: '#fff' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Convenience at Your Fingertips
              </Typography>
              <Typography variant="body1" paragraph>
                Recharge your vehicle from anywhere at any time using our online platform. No more waiting in line or dealing with crowded stations. It’s all about convenience.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: '#fff' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Save Time and Energy
              </Typography>
              <Typography variant="body1" paragraph>
                By recharging your vehicle online, you save precious time and reduce your energy consumption. This is an efficient solution for busy drivers.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: '#fff' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Secure Transactions
              </Typography>
              <Typography variant="body1" paragraph>
                Our platform uses top-of-the-line encryption to ensure that your payment information is secure. Your transactions are safe with us.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default InfrastructureUpdates;
