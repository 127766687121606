import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function PrivacyPolicy() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        paddingTop: '80px',
        backgroundColor: '#f8f9fa',
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#1a1a1a', marginTop: '50px' }}>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          At Recharge Road & Transport Authority, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard the data you provide when using our website and services.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#1a1a1a' }}>
          Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          When you visit our website or use our online services, we may collect the following types of information:
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>Personal information, such as your name, email address, and phone number, when you register for an account or contact us for support.</li>
            <li>Vehicle information, including your license plate number and vehicle details, when you use our online recharge services.</li>
            <li>Payment information, such as credit card details, when you make a transaction on our website. This information is securely processed by our trusted third-party payment providers.</li>
            <li>Usage data, including your IP address, browser type, and pages visited on our site, to analyze trends and improve our services.</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#1a1a1a' }}>
          How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect to:
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>Provide and maintain our services, including processing your online recharge transactions.</li>
            <li>Communicate with you about your account, transactions, and updates to our services.</li>
            <li>Personalize your experience and deliver relevant content and advertisements.</li>
            <li>Detect, prevent, and address technical issues and fraudulent activities.</li>
            <li>Comply with legal obligations and enforce our Terms of Service.</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#1a1a1a' }}>
          Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement a variety of security measures to maintain the safety of your personal information. These measures include secure servers, encryption, and restricted access to sensitive data. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#1a1a1a' }}>
          Third-Party Disclosure
        </Typography>
        <Typography variant="body1" paragraph>
          We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as described in this Privacy Policy. We may share your information with trusted third parties who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#1a1a1a' }}>
          Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph sx ={{marginBottom: '50px'}}>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top of the policy.
        </Typography>
      </Container>
    </Box>
  );
}

export default PrivacyPolicy;
