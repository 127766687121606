import React from 'react';
import { Container, Typography, Box, Grid, Paper, Button, List, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { motion } from 'framer-motion';

function Home() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        paddingTop: '80px',
        backgroundColor: '#f8f9fa',
        position: 'relative',
      }}
    >
      <Container maxWidth="md">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography 
            variant="h4" 
            align="center" 
            gutterBottom
            sx={{ fontSize: 'calc(24px + 30px)', fontWeight: 'bold', marginTop: '50px' }} // Жирный шрифт и отступ сверху
          >
            Welcome to Recharge Transport Authority
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            The official platform of the Recharge Transport Authority, where we bring you the latest advancements in transportation technology, eco-friendly initiatives, and updates on vehicle recharge infrastructure.
          </Typography>

          <Typography variant="body1" align="left" paragraph sx={{ marginTop: '2rem' }}>
            On our platform, you will find:
          </Typography>
          
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon sx={{ color: 'green' }} />
              </ListItemIcon>
              <ListItemText primary="Comprehensive tutorials for seamless vehicle recharging using our platform." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon sx={{ color: 'green' }} />
              </ListItemIcon>
              <ListItemText primary="The latest news and innovations in transportation infrastructure." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon sx={{ color: 'green' }} />
              </ListItemIcon>
              <ListItemText primary="Exclusive interviews with pioneers and thought leaders in eco-friendly transport." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon sx={{ color: 'green' }} />
              </ListItemIcon>
              <ListItemText primary="Expert tips to optimize your vehicle's energy efficiency and performance." />
            </ListItem>
          </List>
          
          <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
            <Button variant="contained" color="primary" href="#posts">
              Explore Our Latest Insights
            </Button>
          </Box>
        </motion.div>

        {/* Benefits Section */}
        <Grid container spacing={3} sx={{ marginBottom: '4rem' }}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ padding: '1rem', textAlign: 'center', backgroundColor: '#fff', border: '1px solid #1a1a1a', borderRadius: '8px' }}>
              <img src="https://cdn.discordapp.com/attachments/1270685295674658927/1271868112735698965/ComfyUI_temp_vkval_00003_.png?ex=66b8e6fc&is=66b7957c&hm=f70bcf055d58457670b71b6c8e756fe8ea29b8827c0851bbc8db5ee8e1834353&" alt="Step by step" style={{ width: '100%', height: 'auto', borderRadius: '4px' }} />
              <Typography variant="h6" gutterBottom sx={{ marginTop: '1rem' }}>
                How to Recharge Your Vehicle Online
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: '1rem' }}>
                Learn how to effortlessly recharge your vehicle through our streamlined online platform.
              </Typography>
              <Button variant="contained" color="primary" href="/#/recharge-guide">
              Read More
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ padding: '1rem', textAlign: 'center', backgroundColor: '#fff', border: '1px solid #1a1a1a', borderRadius: '8px' }}>
              <img src="https://cdn.discordapp.com/attachments/1270685295674658927/1271868391883407361/image.png?ex=66b8e73e&is=66b795be&hm=e67c6c76cfb2a50a5b567a18b7d33fa14689663eeb2f5555e46e92f7f62f2666&" alt="Infrastructure" style={{ width: '100%', height: 'auto', borderRadius: '4px' }} />
              <Typography variant="h6" gutterBottom sx={{ marginTop: '1rem' }}>
                Benefits of Online Vehicle Recharging
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: '1rem' }}>
                Discover the convenience and efficiency of recharging your vehicle online anytime, anywhere.
              </Typography>
              <Button variant="contained" color="primary" href="/#/infrastructure-updates">
              Read More
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ padding: '1rem', textAlign: 'center', backgroundColor: '#fff', border: '1px solid #1a1a1a', borderRadius: '8px' }}>
              <img src="https://media.discordapp.net/attachments/1270685295674658927/1271869112326295573/ComfyUI_temp_vkval_00004_.png?ex=66b8e7ea&is=66b7966a&hm=4e4754308cb29de5cacff489a0fcbfb1a1ab47a45c2c8f0d99a713e08b9da5c9&=&format=webp&quality=lossless&width=1035&height=666" alt="Expert Interviews" style={{ width: '100%', height: 'auto', borderRadius: '4px' }} />
              <Typography variant="h6" gutterBottom sx={{ marginTop: '1rem' }}>
                Recharge Transport: Our Mission
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: '1rem' }}>
                Explore our mission to revolutionize transport with sustainable and innovative solutions.
              </Typography>
              <Button variant="contained" color="primary" href="/#/mission">
              Read More
              </Button>
            </Paper>
          </Grid>
        </Grid>

        {/* Contact Form Section */}
        <Box
          sx={{
            padding: '2rem',
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            marginBottom: '4rem',
          }}
        >
          <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#1a1a1a' }}>
            Contact Us
          </Typography>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  type="email"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Message"
                  variant="outlined"
                  multiline
                  rows={4}
                  required
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" type="submit">
                  Send Message
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Box>
  );
}

export default Home;
