import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Navbar from './components/Navbar';
import Home from './components/Home';
import Services from './components/Services'; 
import PrivacyPolicy from './components/PrivacyPolicy';
import Footer from './components/Footer';
import Contacts from './components/Contacts';
import RechargeGuide from './components/RechargeGuide';
import InfrastructureUpdates from './components/InfrastructureUpdates';
import Mission from './components/Mission';
import CookieConsent from "react-cookie-consent";

// Создаём тему для приложения
const theme = createTheme({
  palette: {
    primary: {
      main: '#1a1a1a', // Темный серый/черный для основного цвета
    },
    secondary: {
      main: '#ffd700', // Золотой для акцентов
    },
    background: {
      default: '#ffffff', // Чисто белый фон
    },
  },
  typography: {
    fontFamily: 'Helvetica, Arial, sans-serif',
    h4: {
      fontWeight: 500,
      color: '#1a1a1a',
    },
    body1: {
      fontSize: '1.1rem',
      color: '#333333',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} /> 
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
		  <Route path="/recharge-guide" element={<RechargeGuide />} />
		  <Route path="/infrastructure-updates" element={<InfrastructureUpdates />} />
		  <Route path="/mission" element={<Mission />} />
		  <Route path="/contacts" element={<Contacts />} />
        </Routes>
        <Footer />
		<CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="myAwesomeCookieName"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <span style={{ fontSize: "15px" }}>Learn more in our Privacy Policy.</span>
      </CookieConsent>
      </Router>
    </ThemeProvider>
  );
}

export default App;
