import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

function Navbar() {
  return (
    <AppBar position="fixed" style={{ backgroundColor: 'rgba(26, 26, 26, 0.9)', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', padding: '0.5rem 0' }}>
    <Toolbar style={{ justifyContent: 'space-between' }}>
      <Typography
        variant="h6"
        component={RouterLink}
        to="/"
        sx={{
          color: '#fff',
          textDecoration: 'none',
          fontWeight: 'bold',
          fontSize: '20px',
          marginLeft: '20px', // отступ слева
          transition: 'color 0.3s',
          '&:hover': {
            color: '#ffeb3b',
          },
        }}
      >
        Recharge Transport
      </Typography>
        <Box display="flex" alignItems="center" gap="30px">
            <Typography
            variant="h6"
            component={RouterLink}
            to="/contacts"
            sx={{
              color: '#fff',
              textDecoration: 'none',
              fontWeight: 'bold',
              fontSize: '16px',
              transition: 'color 0.3s',
              '&:hover': {
                color: '#ffeb3b',
              },
            }}
          >
            Contacts
          </Typography>
          <Typography
            variant="h6"
            component={RouterLink}
            to="/"
            sx={{
              color: '#fff',
              textDecoration: 'none',
              fontWeight: 'bold',
              fontSize: '16px',
              transition: 'color 0.3s',
              '&:hover': {
                color: '#ffd700',
              },
            }}
          >
            Home
          </Typography>
          <Typography
            variant="h6"
            component={RouterLink}
            to="/services"
            sx={{
              color: '#fff',
              textDecoration: 'none',
              fontWeight: 'bold',
              fontSize: '16px',
              transition: 'color 0.3s',
              '&:hover': {
                color: '#ffd700',
              },
            }}
          >
            About
          </Typography>
          <Typography
            variant="h6"
            component={RouterLink}
            to="/privacy-policy"
            sx={{
              color: '#fff',
              textDecoration: 'none',
              fontWeight: 'bold',
              fontSize: '16px',
              transition: 'color 0.3s',
              '&:hover': {
                color: '#ffd700',
              },
            }}
          >
            Privacy Policy
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
