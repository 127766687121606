import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import { motion } from 'framer-motion';

function Services() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        paddingTop: '80px',
        backgroundColor: '#f8f9fa',
      }}
    >
      <Container maxWidth="md">
        <motion.div
          id="about-section"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          style={{ marginBottom: '4rem' }}
        >
          <Typography variant="h4" component="h2" gutterBottom sx={{ fontSize: 'calc(24px + 10px)', fontWeight: 'bold', color: '#1a1a1a', marginTop: '50px' }}>
            About Recharge Transport
          </Typography>
          <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: '#fff' }}>
            <Typography variant="body1" paragraph>
              Welcome to Recharge Transport, your trusted partner in providing sustainable and efficient transportation solutions. Our platform is designed to help you manage your vehicle's energy needs with ease, offering a comprehensive range of services tailored to modern transportation demands.
            </Typography>
          </Paper>
        </motion.div>

        <motion.div
          id="mission-section"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <Typography variant="h4" component="h2" gutterBottom sx={{ fontSize: 'calc(24px + 5px)', fontWeight: 'bold', color: '#1a1a1a' }}>
            Our Mission
          </Typography>
          <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: '#fff' }}>
            <Typography variant="body1" paragraph>
              At Recharge Transport, our mission is to accelerate the adoption of electric vehicles by providing accessible and reliable recharging infrastructure. We aim to empower drivers with the tools they need to transition to a cleaner, more sustainable future.
            </Typography>
          </Paper>
        </motion.div>

        <motion.div
          id="values-section"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }} 
        >
          <Typography variant="h4" component="h2" gutterBottom sx={{ fontSize: 'calc(24px + 5px)', fontWeight: 'bold', color: '#1a1a1a', marginTop: '50px' }}>
            Our Values
          </Typography>
          <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: '#fff' }}>
            <Typography variant="body1" paragraph>
              We believe in sustainability, innovation, and customer satisfaction. Our values guide every aspect of our business, from the development of cutting-edge recharging solutions to our commitment to exceptional service. We strive to create a positive impact on the environment while supporting the growing community of electric vehicle users.
            </Typography>
          </Paper>
        </motion.div>

        <motion.div
          id="services-section"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }} 
        >
          <Typography variant="h4" component="h2" gutterBottom sx={{ fontSize: 'calc(24px + 5px)', fontWeight: 'bold', color: '#1a1a1a', marginTop: '50px'}}>
            Our Services
          </Typography>
          <Paper elevation={3} sx={{ padding: '2rem', backgroundColor: '#fff', marginBottom: '50px' }}>
            <Typography variant="body1" paragraph>
              Recharge Transport offers a wide range of services to meet the needs of today's drivers. From rapid charging stations located across the country to an intuitive online platform for managing your energy consumption, we provide the tools and resources necessary to keep you on the move. Join us in driving the future of transportation.
            </Typography>
          </Paper>
        </motion.div>
      </Container>
    </Box>
  );
}

export default Services;
