import React from 'react';
import { Container, Typography, Box, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, IconButton, TextField, Button } from '@mui/material'; // Добавлены импорты TextField и Button
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

function Contacts() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        paddingTop: '80px',
        backgroundColor: '#f8f9fa',
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" gutterBottom sx={{ fontSize: 'calc(24px + 20px)', fontWeight: 'bold', marginBottom: '2rem', marginTop: '50px' }}>
          Contact Us
        </Typography>
        <Grid container spacing={4}>
          {/* Contact Information Section */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: '2rem', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Recharge Road & Transport Authority
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary="894 Declan Parks Apt. 131" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText primary="+1 (220) 646-0632" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText primary="info@recharge-transport.com" />
                  </ListItem>
                </List>
              </Box>
              <Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: '2rem' }}>
                  Office Hours:
                </Typography>
                <Typography variant="body1" paragraph>
                  Monday - Friday: 9:00am - 9:00pm
                </Typography>
                <Typography variant="body1" paragraph>
                  Saturday: 10:00am - 5:00pm
                </Typography>
                <Typography variant="body1" paragraph>
                  Sunday: Closed
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: '2rem' }}>
                  Connect With Us:
                </Typography>
                <Box>
                  <IconButton href="https://facebook.com" target="_blank" color="primary" aria-label="Facebook">
                    <FacebookIcon />
                  </IconButton>
                  <IconButton href="https://twitter.com" target="_blank" color="primary" aria-label="Twitter">
                    <TwitterIcon />
                  </IconButton>
                  <IconButton href="https://instagram.com" target="_blank" color="primary" aria-label="Instagram">
                    <InstagramIcon />
                  </IconButton>
                </Box>
              </Box>
            </Paper>
          </Grid>
          {/* Contact Form Section */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: '2rem' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Send Us a Message
              </Typography>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Name"
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      variant="outlined"
                      type="email"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Message"
                      variant="outlined"
                      multiline
                      rows={4}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" type="submit">
                      Send Message
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Contacts;
